.intro {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: url('../../public/img/masjid.jpeg') center center no-repeat;
  background-size: cover;
  position: relative;
}

.intro .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
}

.container {
  position: relative;
  z-index: 2;
}

.intro-text {
  text-align: center;
  padding: 0.5rem;
  color: white;
  margin-top: 0;
}

.stats-cards {
  display: flex;
  justify-content: center;
  gap: 3rem;
  padding: 1rem;
  position: relative;
  z-index: 2;
  margin-top: -4rem;
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.stat-card {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 2.5rem;
  border-radius: 10px;
  text-align: center;
  width: 350px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
}

.card-icon {
  font-size: 3.6rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.stat-card h3 {
  color: #fff;
  margin-bottom: 1rem;
  font-size: 2.2rem;
  font-weight: bold;
}

.stat-card p {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.6rem;
  line-height: 1.6;
}

/* Make it responsive */
@media (max-width: 768px) {
  .stats-cards {
    flex-direction: column;
    align-items: center;
  }

  .stat-card {
    width: 90%;
    max-width: 350px;
  }
}

/* Make sure all content is above overlay */
.intro > .container,
.intro > .stats-cards {
  position: relative;
  z-index: 2;
}

/* Optional: if you need to adjust vertical spacing */
.intro-text h1 {
  margin-bottom: 0.3rem;
}

.intro-text p {
  margin-bottom: 0.5rem;
}