#portfolio {
  width: 100%;
  padding: 40px 0;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-title {
  text-align: center;
  margin-bottom: 40px;
  width: 100%;
}

.section-title h2 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.portfolio-items {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 2vw;
  width: 95%;
  max-width: 1800px;
  margin: 0 auto;
  padding: 20px;
}

.gallery-item-wrapper {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Tablet */
@media (max-width: 1200px) {
  .portfolio-items {
    grid-template-columns: repeat(2, 1fr);
  }
  .gallery-item-wrapper {
    height: 400px;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .portfolio-items {
    grid-template-columns: 85vw;
    gap: 20px;
    padding: 10px;
  }
  .section-title h2 {
    font-size: 28px;
  }
  .gallery-item-wrapper {
    aspect-ratio: 3/2;
  }
}

.gallery-section {
  width: 100%;
  padding: 40px 0;
  background: #f9f9f9;
}

.gallery-title {
  text-align: center;
  margin-bottom: 30px;
}

.gallery-title h2 {
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-template-rows: repeat(3, 1fr);    /* 3 rows */
  gap: 20px;
  width: min(90%, 1200px);  /* Constrain width */
  aspect-ratio: 1/1;        /* Make it square */
  margin: 0 auto;
  padding: 20px;
}

.gallery-item {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.gallery-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-image:hover {
  transform: scale(1.05);
}

/* Overlay styles */
.gallery-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);  /* Slightly darker overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
}

.gallery-title-overlay {
  color: white;
  font-size: 2.5rem;  /* Increased from 1.5rem */
  text-align: center;
  font-weight: 600;   /* Made slightly bolder */
  line-height: 1.2;   /* Better line height for larger text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);  /* Added subtle shadow for better readability */
}

/* Hover effects */
.gallery-item:hover .gallery-image {
  transform: scale(1.05);
}

.gallery-item:hover .gallery-overlay {
  opacity: 1;
}

/* Mobile */
@media (max-width: 768px) {
  .gallery-grid {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(9, 1fr);
    width: 85%;
    aspect-ratio: auto;
    gap: 15px;
  }
  
  .gallery-item {
    height: 300px;
  }
  
  .gallery-title h2 {
    font-size: 28px;
  }
}